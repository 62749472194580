<template>
  <img :src="_src" :alt="alt || src" loading="lazy" />
</template>

<script>
export default {
  name: "Cloudinary",
  props: {
    src: { type: String, required: true },
    alt: { type: String, default: "" },
    params: { type: String, default: "f_auto,q_auto" },
  },
  computed: {
    _src() {
      const p = this.params !== "" ? this.params + "/" : "";
      return `https://res.cloudinary.com/kawarimidoll/image/upload/${p}v1617841705/blog/${this.src}`;
    },
  },
};
</script>
